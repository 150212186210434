export default function getEnvironment() {
    var url = new URL(window.location.href);
    const urlHost = url.host.replace("www.", "");

    var environment = "";
    if (urlHost.includes("localhost"))
        environment = "local";

    else if (urlHost.includes("stg-admin"))
        environment = "staging";

    else
        environment = "production";

    return environment;
}