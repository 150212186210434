import axios from "axios";
import store from "../../store";

export default{
  async getRequest(requestParams) {
    let headers = {};
    if (requestParams.needAuthroization == true) {
      headers.Authorization = `Bearer ${requestParams.accessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(requestParams.url, { headers })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          store.dispatch("appConfiguration/setSnackbar", {
            color: "red",
            text: error.response.data.errorObject.userErrorText,
            isActive: true,
          });
          reject(error);
        });
    });
  },

  async postRequest(requestParams) {
    let headers = {};
    if (requestParams.needAuthroization == true) {
      headers.Authorization = `Bearer ${requestParams.accessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(requestParams.url, requestParams.payload, { headers })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          store.dispatch("appConfiguration/setSnackbar", {
            color: "red",
            text: error.response.data.errorObject.userErrorText,
            isActive: true,
          });
          reject(error);
        });
    });
  },
  async putRequest(requestParams) {
    let headers = {};
    if (requestParams.needAuthroization == true) {
      headers.Authorization = `Bearer ${requestParams.accessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .put(requestParams.url, requestParams.payload, { headers })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          store.dispatch("appConfiguration/setSnackbar", {
            color: "red",
            text: error.response.data.errorObject.userErrorText,
            isActive: true,
          });
          reject(error);
        });
    });
  },
  async deleteRequest(requestParams) {
    let headers = {};
    if (requestParams.needAuthroization == true) {
      headers.Authorization = `Bearer ${requestParams.accessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .delete(requestParams.url, { headers })
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          store.dispatch("appConfiguration/setSnackbar", {
            color: "red",
            text: error.response.data.errorObject.userErrorText,
            isActive: true,
          });
          reject(error);
        });
    });
  },
};
