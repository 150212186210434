<template>
  <v-container
    fluid
    style="padding: 20px; height: 100%; background-color: #f0f0f0"
  >
    <div class="content-title">
      {{ $router.currentRoute.meta.title }}
      <v-divider color="black" style="margin-top: 5px"></v-divider>
    </div>

    <router-view></router-view>
  </v-container>
</template>
<script>
export default {};
</script>

<style scoped>
.content-title {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}
</style>
