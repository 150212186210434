import helperApi from "../helper/helper.api";
import store from "../../store";

const GetReportsStatisticsAPI = "Report/Admin/GetReportsStatistics";

export default {
  async getDashboard(startDate, endDate, scoreTreshold) {

    var queries = `?startDate=${startDate}&endDate=${endDate}&scoreTreshold=${scoreTreshold}`;
    const result = await helperApi.getRequest({
      needAuthroization: true,
      accessToken: store.state.user.userInfo.accessToken,
      url: store.state.appConfiguration.apiUrl + GetReportsStatisticsAPI + queries,
    });
    return result;
  },
};
