import helperApi from "../helper/helper.api"
import store from "../../store"

const loginUserAPI = 'AdminUser/Login'
export default {
    async login(loginInfo) {
        const result = await helperApi.postRequest({
            needAuthroization: false,
            url: store.state.appConfiguration.apiUrl + loginUserAPI,
            payload: loginInfo
        })
        return result;
    }
}