import helperApi from "../helper/helper.api"
import store from "../../store"

const findAllUsers = 'User/Admin/FindAllUsers'
const createUser = 'User/Admin/CreateByAdmin'
const generateTokenForUser = 'User/Admin/GenerateTokenForUserByAdmin'
export default {
    async findAllUsers(loginInfo) {
        const result = await helperApi.getRequest({
            needAuthroization: true,
            url: store.state.appConfiguration.apiUrl + findAllUsers,
            accessToken: store.state.adminUser.userInfo.accessToken,
            payload: loginInfo
        })
        return result;
    },
    async createUser(createUserRequest) {
        const result = await helperApi.postRequest({
            needAuthroization: true,
            url: store.state.appConfiguration.apiUrl + createUser,
            accessToken: store.state.adminUser.userInfo.accessToken,
            payload: createUserRequest
        })
        return result;
    },
    async generateTokenForUser(generateTokenForUserRequest) {
        const result = await helperApi.postRequest({
            needAuthroization: true,
            url: store.state.appConfiguration.apiUrl + generateTokenForUser,
            accessToken: store.state.adminUser.userInfo.accessToken,
            payload: generateTokenForUserRequest
        })
        return result;
    }
}