import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'login',
    meta: {
      title: 'Login',
    },
    component: () => import('../views/Login/LoginView.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    meta: {
      title: 'Dashboard',
    },
    component: () => import('../views/Dashboard/DashboardView.vue')
  },
  {
    path: '/Users',
    name: 'users',
    meta: {
      title: 'Users',
    },
    component: () => import('../views/Users/UsersView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
