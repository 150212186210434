import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";

Vue.config.productionTip = false;

var userInfo = window.localStorage.getItem("userInfo");
if (userInfo != null) {
  store.commit("adminUser/setAdminUser", JSON.parse(userInfo));
} else {
  console.log("isnull")
}

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
