<template>
  <div>
    <v-snackbar
      :color="appConfiguration.snackbarSettings.color"
      v-model="appConfiguration.snackbarSettings.isActive"
      timeout="-1"
      top
    >
      <div style="font-size: 16px; text-align: center">
        {{ appConfiguration.snackbarSettings.text }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["appConfiguration"]),
  },
};
</script>
