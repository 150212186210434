<template>
  <v-app v-if="$store.state.adminUser.userInfo.isAuthorized">
    <InfoBar />
    <AppBar />
    <AppDrawer />
    <v-main>
      <MainView />
    </v-main>
  </v-app>
  <v-app v-else>
    <InfoBar />
    <router-view></router-view>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import AppDrawer from "./components/AppDrawer.vue";
import MainView from "./components/MainView.vue";
import InfoBar from "./components/InfoBar.vue";

export default {
  name: "App",
  components: {
    AppBar,
    AppDrawer,
    MainView,
    InfoBar,
  },
  mounted() {
    if (!this.$store.state.adminUser.userInfo.isAuthorized) {
      this.$router.push("/login");
    }
  },
};
</script>

<style>
span {
  font-weight: 700;
  font-size: 16px;
}

.v-btn__content {
  letter-spacing: normal;
}
</style>
