import userApi from "../../api/user/user.api";

const state = () => ({
    users: []
});

const getters = {
    getUsers(state) {
        return state.users;
    },
    //write a getter to get a userToken by userId
    getUserToken: (state) => (userId) => {
        const user = state.users.find((user) => user._id === userId);
        return user ? user.jwtToken : null;
    },
};

const actions = {
    async findAllUsers({ commit }) {
        return new Promise((resolve) => {
            userApi
                .findAllUsers()
                .then((result) => {
                    commit("setUsers", result);
                    resolve({ success: true });
                })
                .catch(() => {
                    resolve({ success: false });
                });
        });
    },
    async createUser({ commit }, user) {
        return new Promise((resolve) => {
            userApi
                .createUser(user)
                .then((data) => {
                    commit("addUser", data);
                    resolve({ success: true });
                })
                .catch(() => {
                    resolve({ success: false });
                });
        });
    },
    async generateTokenForUser({ commit }, user) {
        return new Promise((resolve) => {
            userApi
                .generateTokenForUser({ _id: user._id })
                .then((data) => {
                    commit("setUserToken", data);
                    resolve({ success: true });
                })
                .catch(() => {
                    resolve({ success: false });
                });
        });
    },
};

const mutations = {
    setUsers(state, users) {
        state.users = users;
    },
    addUser(state, user) {
        state.users.push(user);
    },
    setUserToken(state, data) {
        const userId = data._id;
        const token = data.jwtToken;
        const user = state.users.find((user) => user._id === userId);
        if (user) {
            user.jwtToken = token;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};