import adminUserApi from "../../api/adminUser/adminUser.api";

const state = () => ({
    userInfo: {
        accessToken: "",
        fullname: "",
        isAuthorized: false,
    }
});

const getters = {

};

const actions = {
    async login({ commit }, loginInfo) {
        return new Promise((resolve) => {
            adminUserApi
                .login(loginInfo)
                .then((result) => {
                    commit("setAdminUser", result);
                    resolve({ success: true });
                })
                .catch(() => {
                    resolve({ success: false });
                });
        });
    },
};

const mutations = {
    setAdminUser(state, loginResponse) {
        state.userInfo.accessToken = loginResponse.accessToken;
        state.userInfo.isAuthorized = true;
        state.userInfo.fullname = loginResponse.fullname;
        window.localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};