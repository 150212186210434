<template>
  <v-app-bar color="primary" dark app>
    <v-toolbar-title>Bridged Admin Portal</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppBar",
  data: () => ({
    n: 0,
  }),
  methods: {
    logout() {
      localStorage.removeItem("userInfo");
      location.reload();
    },
  },
};
</script>