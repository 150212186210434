import dashboardApi from "../../api/dashboard/dashboard.api";

const state = () => ({
    reportsStatistics: [],
    usersStatistics: [],
    rankingLeaguesTopThree: []
});

const getters = {
    getReportsStatistics(state) {
        return state.reportsStatistics;
    },
    getUsersStatistics(state) {
        return state.usersStatistics;
    },
    getRankingLeaguesTopThree(state) {
        return state.rankingLeaguesTopThree;
    }
};

const actions = {
    async getReportsStatistics({ commit }, { startDate, endDate, scoreTreshold }) {
        const reportsStatistics = await dashboardApi.getReportsStatistics(startDate, endDate, scoreTreshold);
        commit("setReportsStatistics", reportsStatistics);
    },
    async getUsersStatistics({ commit }, { startDate, endDate }) {
        const usersStatistics = await dashboardApi.getUsersStatistics(startDate, endDate);
        commit("setUsersStatistics", usersStatistics);
    },

    async getRankingLeaguesTopThree({ commit }) {
        const rankingLeaguesTopThree = await dashboardApi.getRankingLeaguesTopThree();
        commit("setRankingLeaguesTopThree", rankingLeaguesTopThree);
    }
};

const mutations = {
    setReportsStatistics(state, reportsStatistics) {
        state.reportsStatistics = reportsStatistics.reportsStatistics;
    },
    setUsersStatistics(state, usersStatistics) {
        state.usersStatistics = usersStatistics.usersStatistics;
    },
    setRankingLeaguesTopThree(state, rankingLeaguesTopThree) {
        state.rankingLeaguesTopThree = rankingLeaguesTopThree;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
