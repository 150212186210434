<template>
  <v-navigation-drawer color="primary" dark app absoulute permanent>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="../assets/logo.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Bridged Admin</v-list-item-title>
          <v-list-item-subtitle>Admin</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.routeName"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", routeName: "/", icon: "mdi-home-city" },
        {
          title: "Users",
          routeName: "/users",
          icon: "mdi-account-group-outline",
        }
      ],
    };
  },
};
</script>