import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: 'black',
        secondary: '#FF7E46',
        accent: '#2D3142',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  rtl: false,
});
